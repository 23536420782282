import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Typography, Fade, Paper,
} from '@material-ui/core';
import {
  FolderOpen, RotateLeft,
  ArrowUpward,
  Check,
  BuildOutlined,

} from '@material-ui/icons';
import { SERVICE, ACCESS } from '../redux/action-constants';
import DashboardButton from './DashboardButton';
import { ACCESS_REQUEST_STATUSES, SERVICE_REQUEST_STATUSES } from '../util/constants';
import { ServiceDashboardButton, AccessDashboardButton } from '../containers/DashboardButton';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
  },
  box: {
    width: '27%',
    // borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '95%',
  },
  label: {
  },
}));

const initialState = {
  [SERVICE]: {
  },
  [ACCESS]: {
  },
  notification: {
    upcoming: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'parseRequest': {
      const { payload, requestType } = action;
      let countMap = {};
      if (payload.length > 0) {
        payload.forEach(request => {
          const { request_status: requestStatus } = request;
          countMap = {
            ...countMap,
            [requestStatus]: countMap[requestStatus] ? countMap[requestStatus] += 1 : 1,
          };
        });
      }
      return {
        ...state,
        [requestType]: countMap,
      };
    }
    case 'parseMaintenance':
      // TODO:
      return state;
    default:
      return state;
  }
};

function Dashboard(props) {
  const {
    services,
    accesses,
    fetchAll,
    switchToService,
    switchToAccess,
    filterService,
    filterAccess,
    resetFilters,
  } = props;
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { service, access, notification } = state;

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  useEffect(() => {
    dispatch({ type: 'parseRequest', payload: services, requestType: SERVICE });
  }, [services]);
  useEffect(() => {
    dispatch({ type: 'parseRequest', payload: accesses, requestType: ACCESS });
  }, [accesses]);

  const navigateToAccess = filter => {
    resetFilters(ACCESS);
    filterAccess(filter);
    switchToAccess();
  };

  const navigateToService = filter => {
    resetFilters(SERVICE);
    filterService(filter);
    switchToService();
  };

  return (
    <Fade in>
      <div
        className={ classes.container }
        data-cy='home-screen'
      >
        <Paper
          elevation={ 3 }
          className={ classes.box }
        >
          <div
            className={ classes.label }
          >
            <Typography
              variant='h6'
              data-cy='access-request-label'
            >
              Access Requests
            </Typography>
          </div>
          <AccessDashboardButton
            value={ access[ACCESS_REQUEST_STATUSES.IN_PROGRESS] }
            label='IN PROGRESS'
            Icon={ RotateLeft }
            onClick={ () => navigateToAccess(ACCESS_REQUEST_STATUSES.IN_PROGRESS) }
            data-cy='access-request-in-progress'
          />
          <AccessDashboardButton
            value={ access[ACCESS_REQUEST_STATUSES.ACCEPTED] }
            label='ACCEPTED'
            Icon={ ArrowUpward }
            onClick={ () => navigateToAccess(ACCESS_REQUEST_STATUSES.ACCEPTED) }
            data-cy='access-request-accepted'
          />
          <AccessDashboardButton
            value={ access[ACCESS_REQUEST_STATUSES.CLOSED] }
            label='CLOSED'
            Icon={ Check }
            onClick={ () => navigateToAccess(ACCESS_REQUEST_STATUSES.CLOSED) }
            data-cy='access-request-closed'
          />
        </Paper>
        <Paper
          elevation={ 3 }
          className={ classes.box }
        >
          <div
            className={ classes.label }
          >
            <Typography
              variant='h6'
              data-cy='service-request-label'
            >
              Service Requests
            </Typography>
          </div>
          <ServiceDashboardButton
            value={ service[SERVICE_REQUEST_STATUSES.OPEN] }
            label='OPEN'
            Icon={ FolderOpen }
            onClick={ () => navigateToService(SERVICE_REQUEST_STATUSES.OPEN) }
            data-cy='service-request-open'
          />
          <ServiceDashboardButton
            value={ service[SERVICE_REQUEST_STATUSES.IN_PROGRESS] }
            label='IN PROGRESS'
            Icon={ RotateLeft }
            onClick={ () => navigateToService(SERVICE_REQUEST_STATUSES.IN_PROGRESS) }
            data-cy='service-request-in-progress'
          />
          <div style={ { height: '25%' } } />
        </Paper>
        <Paper
          elevation={ 3 }
          className={ classes.box }
        >
          <div
            className={ classes.label }
          >
            <Typography
              variant='h6'
              data-cy='notification-label'
            >
              Notifications
            </Typography>
          </div>
          <DashboardButton
            value={ notification.upcoming }
            label='Maintenance'
            Icon={ BuildOutlined }
            onClick={ () => console.log(
              // TODO:
              'todo maintenance button',
            ) }
            isLoading
            data-cy='notification-maintenance'
          />
          <div style={ { height: '25%' } } />
          <div style={ { height: '25%' } } />
        </Paper>
      </div>
    </Fade>
  );
}

Dashboard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  accesses: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchAll: PropTypes.func.isRequired,
  switchToService: PropTypes.func.isRequired,
  switchToAccess: PropTypes.func.isRequired,
  filterService: PropTypes.func.isRequired,
  filterAccess: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default Dashboard;
