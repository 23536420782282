import Axios from 'axios';
import {
  FILTER_REQUESTS,
  CLEAR_FILTER, SERVICE,
  REQUEST_LOADING, ACCESS,
  SET_REQUEST,
  SET_MOUNTED,
  SET_DRAWER_ITEM,
  CHANGE_DRAWER_REQUEST,
  RESET_FILTER,
  SET_CUSTOMERS,
  SET_CUSTOMERS_LOADING,
  SET_ACCESS_CONTACTS,
  SET_ACCESS_CONTACTS_LOADING,
  DOWNLOAD_TABLE,
} from './action-constants';
import { generateCustomers, generateAccessContacts } from '../util/constants';

const API = process.env.REACT_APP_AWS_API_URL;

export const changeFilterRequests = (requestType, payload, filter, params) => dispatch => {
  dispatch({
    type: FILTER_REQUESTS, requestType, payload, filter, params,
  });
};

export const clearRequestFilter = (requestType, filter) => dispatch => {
  dispatch({ type: CLEAR_FILTER, requestType, filter });
};

export const fetchServiceRequest = () => dispatch => {
  dispatch({ type: REQUEST_LOADING, requestType: SERVICE });
  Axios.get(`${API}/requests`, {
    params: {
      request_type: 'service',
    },
  }).then(response => {
    dispatch({ type: SET_REQUEST, payload: response.data.requests, requestType: SERVICE });
  }).catch(error => {
    console.error(error);
  });
};

export const serviceMounted = () => dispatch => {
  dispatch({ type: SET_MOUNTED, requestType: SERVICE });
};

export const fetchAccessRequest = () => dispatch => {
  dispatch({ type: REQUEST_LOADING, requestType: ACCESS });
  Axios.get(`${API}/requests`, {
    params: {
      request_type: 'access',
    },
  }).then(response => {
    dispatch({ type: SET_REQUEST, payload: response.data.requests, requestType: ACCESS });
  }).catch(error => {
    console.error(error);
  });
};

export const accessMounted = () => dispatch => {
  dispatch({ type: SET_MOUNTED, requestType: ACCESS });
};

export const setDrawerItem = item => dispatch => {
  dispatch({ type: SET_DRAWER_ITEM, payload: item });
};

export const changeDrawerRequest = (payload, filter) => dispatch => {
  dispatch({
    type: CHANGE_DRAWER_REQUEST, payload, filter,
  });
};
// Fetches Service, access requests,
// TODO: Maintenance
export const fetchAll = () => dispatch => {
  fetchServiceRequest()(dispatch);
  fetchAccessRequest()(dispatch);
};

export const resetFilters = requestType => dispatch => {
  dispatch({ type: RESET_FILTER, requestType });
};

export const setCustomersLoading = bool => dispatch => {
  dispatch({ type: SET_CUSTOMERS_LOADING, payload: bool });
};

export const fetchCustomers = () => dispatch => {
  setCustomersLoading(true)(dispatch);
  setTimeout(() => {
    dispatch({ type: SET_CUSTOMERS, payload: generateCustomers() });
  }, 500);
};

export const setAccessContactsLoading = bool => dispatch => {
  dispatch({ type: SET_ACCESS_CONTACTS_LOADING, payload: bool });
};

export const fetchAccessContacts = () => dispatch => {
  setAccessContactsLoading(true)(dispatch);
  setTimeout(() => {
    dispatch({ type: SET_ACCESS_CONTACTS, payload: generateAccessContacts() });
  }, 500);
};

export const downloadTable = (requestType, format) => dispatch => {
  dispatch({
    type: DOWNLOAD_TABLE, requestType, format,
  });
};
