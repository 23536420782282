import { connect } from 'react-redux';
import { serviceLoadingSelector, accessLoadingSelector } from '../redux/selectors';
import DashboardButton from '../components/DashboardButton';

const mapServiceStateToProps = state => ({
  isLoading: serviceLoadingSelector(state),
});

export const ServiceDashboardButton = connect(mapServiceStateToProps)(DashboardButton);

const mapAccessStateToProps = state => ({
  isLoading: accessLoadingSelector(state),
});

export const AccessDashboardButton = connect(mapAccessStateToProps)(DashboardButton);
