import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Badge, Typography, LinearProgress, makeStyles, Fade,
} from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    height: '25%',
    width: '80%',
  },
  buttonContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    height: 40,
    width: 40,
  },
  progress: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
});

function DashboardButton(props) {
  const {
    value, label, Icon, onClick, isLoading, 'data-cy': dataCy,
  } = props;
  const classes = useStyles();
  return (
    <Button
      variant='outlined'
      className={ classes.button }
      onClick={ onClick }
      data-cy={ dataCy }
    >
      <div className={ classes.buttonContentContainer }>
        <Badge
          anchorOrigin={ {
            horizontal: 'right',
            vertical: 'top',
          } }
          invisible={ value === 0 || value === null }
          max={ 999 }
          badgeContent={ value }
          color='primary'
          data-cy='badge'
        >
          <Icon className={ classes.icon } />
        </Badge>
        <Typography
          data-cy='label'
        >
          {label}
        </Typography>
      </div>
      <Fade
        in={ isLoading }
        timeout={ {
          exit: 500,
        } }
      >
        <div className={ classes.progress }>
          <LinearProgress />
        </div>
      </Fade>
    </Button>
  );
}

DashboardButton.defaultProps = {
  value: null,
};

DashboardButton.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  'data-cy': PropTypes.string.isRequired,
};

export default DashboardButton;
