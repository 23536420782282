export const FILTER_REQUESTS = 'FILTER_REQUESTS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const FILTER_TICKET_NUMBER = 'FILTER_TICKET_NUMBER';
export const CLEAR_TICKET_NUMBER = 'CLEAR_TICKET_NUMBER';
export const FILTER_CUSTOMER = 'FILTER_CUSTOMER';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
export const FILTER_DATA_CENTRE = 'FILTER_DATA_CENTRE';
export const FILTER_STATUS = 'FILTER_STATUS';
export const FILTER_FROM_DATE = 'FILTER_FROM_DATE';
export const FILTER_TO_DATE = 'FILTER_TO_DATE';
export const REQUEST_LOADING = 'REQUEST_LOADING';
export const SET_REQUEST = 'SET_REQUEST';
export const SET_MOUNTED = 'SET_MOUNTED';
export const SET_DRAWER_ITEM = 'SET_DRAWER_ITEM';
export const CHANGE_DRAWER_REQUEST = 'CHANGE_DRAWER_REQUEST';
export const RESET_FILTER = 'RESET_FILTER';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CUSTOMERS_LOADING = 'SET_CUSTOMERS_LOADING';
export const SET_ACCESS_CONTACTS = 'SET_ACCESS_CONTACTS';
export const SET_ACCESS_CONTACTS_LOADING = 'SET_ACCESS_CONTACTS_LOADING';
export const DOWNLOAD_TABLE = 'DOWNLOAD_TABLE';

// reducer state constants
export const ACCESS = 'access';
export const SERVICE = 'service';

export const TICKET_NUMBER = 'ticketNumber';
export const CUSTOMER = 'customer';
export const DATA_CENTRE = 'dataCentre';
export const FROM_DATE = 'fromDate';
export const TO_DATE = 'toDate';
export const STATUS = 'status';

// date filters
export const START = 'start';
export const END = 'end';

// export types
export const XLSX = 'xlsx';
export const CSV = 'csv';
