import React, { useState } from 'react';
import './App.css';
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
  Fab,
  Tooltip,
  makeStyles,
} from '@material-ui/core/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { Provider } from 'react-redux';
import { Brightness4Outlined, Brightness7 } from '@material-ui/icons';
import Cookies from 'universal-cookie';
import { purple } from '@material-ui/core/colors';
import TabNavigation from './containers/TabNavigation';
import configureStore from './redux/store';
import DevTools from './redux/DevTools';
import ErrorBoundary from './components/shared/ErrorBoundary';
import Error from './components/shared/Error';

const store = configureStore();

const darkTheme = responsiveFontSizes(createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: purple[300],
    },
    secondary: {
      main: '#03DAC6',
    },
    error: {
      main: '#CF6679',
    },
  },
}));

const lightTheme = responsiveFontSizes(createMuiTheme());

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    bottom: 50,
    left: 50,
  },
  white: {
    color: theme.palette.common.white,
  },
}));

export default function App() {
  const cookies = new Cookies();
  const [darkMode, setDarkMode] = useState(cookies.get('darkMode') ? JSON.parse(cookies.get('darkMode')) : false);

  const toggleTheme = () => {
    const cookieDark = cookies.get('darkMode');
    const toggleDark = cookieDark !== undefined ? !JSON.parse(cookieDark) : true;
    cookies.set('darkMode', toggleDark, { path: '/' });
    setDarkMode(toggleDark);
  };

  const classes = useStyles();
  return (
    <Provider store={ store }>
      <ThemeProvider theme={ darkMode ? darkTheme : lightTheme }>
        <MuiPickersUtilsProvider utils={ MomentUtils }>
          <DevTools />
          <ErrorBoundary
            fallback={
              <Error />
          }
          >
            <TabNavigation />
          </ErrorBoundary>
          <Tooltip
            title={ darkMode ? 'Use Light Theme' : 'Use Dark Theme' }
          >
            <Fab
              onClick={ toggleTheme }
              className={ classes.fab }
              color='primary'
            >
              {!darkMode && <Brightness4Outlined />}
              {darkMode && <Brightness7 className={ classes.white } />}
            </Fab>
          </Tooltip>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  );
}
