
import React, { useState, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Tabs,
  Tab,
  Typography,
  Box,
} from '@material-ui/core/';
import Home from '../containers/Home';
import ErrorBoundary from './shared/ErrorBoundary';
import Loading from './shared/Loading';
import Error from './shared/Error';
import { ACCESS, SERVICE } from '../redux/action-constants';

const AccessRequestTable = lazy(() => (import('../containers/AccessRequestTable')));
const ServiceRequestTable = lazy(() => (import('../containers/ServiceRequestTable')));

const ACCESS_TAB = 1;
const SERVICE_TAB = 2;


function TabPanel(props) {
  const {
    children, value, index,
  } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={ value !== index }
      id={ `vertical-tabpanel-${index}` }
      aria-labelledby={ `vertical-tab-${index}` }
      style={ {
        height: '100%',
        width: '100%',
      } }
    >
      {value === index && (
        <Box
          style={ {
            height: '100%',
            width: '100%',
          } }
        >
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    // id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  homeContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  tab: {
    float: 'left',
    color: theme.palette.text.primary,
  },
}));

export default function VerticalTabs(props) {
  const { resetFilters } = props;
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    // reset filters
    if (newValue === ACCESS_TAB) resetFilters(ACCESS);
    else if (newValue === SERVICE_TAB) resetFilters(SERVICE);

    setTab(newValue);
  };

  const switchToAccess = () => {
    setTab(ACCESS_TAB);
  };

  const switchToService = () => {
    setTab(SERVICE_TAB);
  };

  return (
    <div className={ classes.root }>
      <div className={ classes.homeContainer }>
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={ tab }
          onChange={ handleChange }
          aria-label='Navigation Tabs'
          className={ classes.tabs }
        >
          <Tab label='Home' { ...a11yProps(0) } className={ classes.tab } data-cy='home-tab' />
          <Tab label='Access Requests' { ...a11yProps(1) } className={ classes.tab } data-cy='access-request-tab' />
          <Tab label='Service Requests' { ...a11yProps(2) } className={ classes.tab } />
          <Tab label='Notifications' { ...a11yProps(3) } className={ classes.tab } />
          <Tab label='Maintenance Notifications' { ...a11yProps(4) } className={ classes.tab } />
          <Tab label='Documents Centre' { ...a11yProps(5) } className={ classes.tab } />
          <Tab label='Feedback' { ...a11yProps(6) } className={ classes.tab } />
        </Tabs>
        <TabPanel value={ tab } index={ 0 }>
          <Home switchToAccess={ switchToAccess } switchToService={ switchToService } />
        </TabPanel>
        <TabPanel value={ tab } index={ ACCESS_TAB }>
          <ErrorBoundary
            fallback={
              <Error />
            }
          >
            <Suspense
              fallback={
                <Loading />
              }
            >
              <AccessRequestTable
                data-cy='access-request-screen'
              />
            </Suspense>
          </ErrorBoundary>
        </TabPanel>
        <TabPanel value={ tab } index={ SERVICE_TAB }>
          <ErrorBoundary
            fallback={
              <Error />
            }
          >
            <Suspense
              fallback={
                <Loading />
              }
            >
              <ServiceRequestTable />
            </Suspense>
          </ErrorBoundary>
        </TabPanel>
        <TabPanel value={ tab } index={ 3 }>
          Item Four
        </TabPanel>
        <TabPanel value={ tab } index={ 4 }>
          Item Five
        </TabPanel>
        <TabPanel value={ tab } index={ 5 }>
          Item Six
        </TabPanel>
        <TabPanel value={ tab } index={ 6 }>
          Item Seven
        </TabPanel>
      </div>
    </div>
  );
}

VerticalTabs.propTypes = {
  resetFilters: PropTypes.func.isRequired,
};
