import {
  date, company, random, name, phone, internet,
} from 'faker';
import moment from 'moment';

export const DATA_CENTRES = {
  1: {
    id: 1,
    name: 'Orbit',
  },
  2: {
    id: 2,
    name: 'Kapua',
  },
  3: {
    id: 3,
    name: 'Abel',
  },
  4: {
    id: 4,
    name: 'Christchurch',
  },
  5: {
    id: 5,
    name: 'Gloucester',
  },
  6: {
    id: 6,
    name: 'Syd1',
  },
  7: {
    id: 7,
    name: 'Mel1',
  },
};

export const SERVICE_REQUEST_STATUSES = {
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  ACCEPTED: 'Accepted',
  COMPLETED: 'Completed',
  DECLINED: 'Declined',
  CANCELLED: 'Cancelled',
};

export const ACCESS_REQUEST_STATUSES = {
  IN_PROGRESS: 'In Progress',
  ACCEPTED: 'Accepted',
  CLOSED: 'Closed',
  DECLINED: 'Declined',
  CANCELLED: 'Cancelled',
};

export const NEW_ACCESS_REQUEST_STATUSES = {
  IN_PROGRESS: 'In Progress',
  ACCEPTED: 'Accepted',
};


export const MAINTENANCE_STATUSES = {
  COMPLETED: 'Completed',
  SCHEDULED: 'Scheduled',
};

// const STATUS_SIZE = Object.keys(REQUEST_STATUSES).length;
// const DATA_CENTRE_SIZE = Object.keys(DATA_CENTRES).length;

// export const generateRequest = () => ({
//   ticketNumber: phone.phoneNumberFormat(),
//   status: Object.values(REQUEST_STATUSES)[Math.floor(Math.random() * (STATUS_SIZE - 1))],
//   fromDate: date.past(),
//   toDate: date.future(),
//   customer: company.companyName(),
//   dataCentre: Object.values(DATA_CENTRES)[Math.floor(Math.random() * (DATA_CENTRE_SIZE - 1))],
// });

// export const generateRequests = () => {
//   const max = Math.floor(Math.random() * 100) + 50; // 50 to 100
//   const data = [];
//   for (let i = 0; i < max; i += 1) {
//     data.push(generateRequest());
//   }
//   return data;
// };

export const generateCustomer = () => ({
  id: random.uuid(),
  customer_name: company.companyName(),
});


export const generateCustomers = () => {
  const max = Math.floor(Math.random() * 100) + 50; // 50 to 100
  let data = [];
  for (let i = 0; i < max; i += 1) {
    data = [...data, generateCustomer()];
  }
  return data;
};

export const DATE_FORMAT = 'll';
export const DATE_TIME_FORMAT = 'llll';

export const ALL = 0;

export const generateAccessContact = () => ({
  id: random.uuid(),
  first_name: name.firstName(),
  last_name: name.lastName(),
  phone: phone.phoneNumberFormat(),
  mobile: phone.phoneNumberFormat(),
  email: internet.email(),
  role: name.jobTitle(),
  organisation: company.companyName(),
  induction_date: moment(date.past()).format('DD/MMM/YYYY:HH:mm:ss Z'),
  expiry_date: moment(date.recent()).format('DD/MMM/YYYY:HH:mm:ss Z'),
});


export const generateAccessContacts = () => {
  const max = Math.floor(Math.random() * 100) + 50; // 50 to 100
  let data = [];
  for (let i = 0; i < max; i += 1) {
    data = [...data, generateAccessContact()];
  }
  return data;
};

export const ACCESS_REQUEST_PRIORITY = [
  'low', 'normal', 'high', 'critical',
];

export const ACCESS = 'ACCESS';
export const ADDITIONAL = 'ADDITIONAL';

export const generateRack = () => ({
  id: random.uuid(),
  name: phone.phoneNumberFormat(),
});


export const generateRacks = () => {
  const max = Math.floor(Math.random() * 100) + 50; // 50 to 100
  let data = [];
  for (let i = 0; i < max; i += 1) {
    data = [...data, generateRack()];
  }
  return data;
};
