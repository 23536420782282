import moment from 'moment';
import XLSX from 'xlsx';
import {
  ALL, DATE_FORMAT,
} from '../util/constants';
import {
  FILTER_REQUESTS,
  CLEAR_FILTER,
  REQUEST_LOADING,
  SET_REQUEST,
  SET_MOUNTED,
  SET_DRAWER_ITEM,
  CHANGE_DRAWER_REQUEST,
  RESET_FILTER,
  SET_CUSTOMERS_LOADING,
  SET_CUSTOMERS,
  SET_ACCESS_CONTACTS_LOADING,
  SET_ACCESS_CONTACTS,
  START,
  END,
  DOWNLOAD_TABLE,
  CSV,
  ACCESS,
} from './action-constants';
import { convertCLFTimeToMoment } from '../util/util';
import { filteredRequestsSelector } from './selectors';

const DEFAULT_DATE_FILTER = {
  start: null,
  end: null,
};

const DEFAULT_FILTERS = {
  ticketNumber: '',
  customer: null,
  dataCentre: 0,
  fromDate: DEFAULT_DATE_FILTER,
  toDate: DEFAULT_DATE_FILTER,
  status: 0,
};

const initialState = {
  access: {
    filters: DEFAULT_FILTERS,
    filteredRequests: [],
    data: [],
    isLoading: false,
    isMounted: false,
    contacts: {
      data: [],
      isLoading: false,
      isLoaded: false,
    },
  },
  service: {
    filters: DEFAULT_FILTERS,
    filteredRequests: [],
    data: [],
    isLoading: false,
    isMounted: false,
  },
  drawerItem: {
    ticketNumber: '',
    customer: '',
    dataCentre: 0,
    fromDate: null,
    toDate: null,
    status: 0,
  },
  customers: {
    data: [],
    isLoading: false,
    isLoaded: false,
  },
};

const filterDate = (filterFrom, filterTo, startDate) => {
  const fromDate = convertCLFTimeToMoment(startDate).format(DATE_FORMAT);
  if (filterFrom && filterTo) {
    return moment(fromDate).isSame(filterFrom.format(DATE_FORMAT))
      || moment(fromDate).isBetween(filterFrom.format(DATE_FORMAT), filterTo.format(DATE_FORMAT), null, '[]');
  } if (filterFrom) {
    return moment(fromDate)
      .isSameOrAfter(filterFrom.format(DATE_FORMAT));
  } if (filterTo) {
    return moment(fromDate)
      .isSameOrBefore(filterTo.format(DATE_FORMAT));
  }
  return true;
};

// Filters data
const filterData = (data, filters) => {
  const newRequests = data.filter(request => {
    const {
      ticket_number: ticketNumber,
      customer_name: customer,
      datacentre_name: datacentreName,
      from_date: fromDate,
      to_date: toDate,
      request_status: status,
    } = request;
    const ticketFilter = ticketNumber.toLowerCase().includes(filters.ticketNumber.toLowerCase());
    const statusFilter = filters.status !== ALL ? status === filters.status : true;

    const fromDateFilter = filterDate(filters.fromDate.start, filters.fromDate.end, fromDate);
    const toDateFilter = filterDate(filters.toDate.start, filters.toDate.end, toDate);
    const customerFilter = filters.customer
      ? customer.toLowerCase().includes(filters.customer.customer_name.toLowerCase()) : true;
    const dataCentreFilter = filters.dataCentre !== ALL ? datacentreName === filters.dataCentre : true;

    return ticketFilter && statusFilter && fromDateFilter && toDateFilter && customerFilter && dataCentreFilter;
  });

  return newRequests;
};

const filterRequests = (state, action) => {
  const {
    requestType, payload, filter, params,
  } = action;
  const newState = {
    ...state,
    [requestType]: {
      ...state[requestType],
      filters: {
        ...state[requestType].filters,
        [filter]: (params === START || params === END) ? {
          ...state[requestType].filters[filter],
          [params]: payload,
        } : payload,
      },
    },
  };
  return {
    ...newState,
    [requestType]: {
      ...newState[requestType],
      filteredRequests: filterData(newState[requestType].data, newState[requestType].filters),
    },
  };
};

const clearFilter = (state, action) => {
  const { requestType, filter } = action;
  const newState = {
    ...state,
    [requestType]: {
      ...state[requestType],
      filters: {
        ...state[requestType].filters,
        [filter]: '',
      },
    },
  };
  return {
    ...newState,
    [requestType]: {
      ...newState[requestType],
      filteredRequests: filterData(newState[requestType].data, newState[requestType].filters),
    },
  };
};

const setLoading = (state, action) => {
  const { requestType } = action;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      isLoading: true,
    },
  };
};

const setRequest = (state, action) => {
  const { payload, requestType } = action;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      filteredRequests: payload,
      data: payload,
      isLoading: false,
    },
  };
};

const setMounted = (state, action) => {
  const { requestType } = action;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      isMounted: true,
    },
  };
};

const setDrawerItem = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    drawerItem: payload,
  };
};

const changeDrawerRequest = (state, action) => {
  const { payload, filter } = action;
  return {
    ...state,
    drawerItem: {
      ...state.drawerItem,
      [filter]: payload,
    },
  };
};

const resetFilter = (state, action) => {
  const { requestType } = action;
  const filters = {
    ...DEFAULT_FILTERS,
    status: ALL,
  };
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      filters,
      filteredRequests: filterData(state[requestType].data, filters),
    },
  };
};

const setCustomerLoading = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    customers: {
      ...state.customers,
      isLoading: payload,
    },
  };
};

const setCustomer = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    customers: {
      ...state.customers,
      data: payload,
      isLoading: false,
      isLoaded: true,
    },
  };
};

const setAccessContactsLoading = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    access: {
      ...state.access,
      contacts: {
        ...state.access.contacts,
        isLoading: payload,
      },
    },
  };
};

const setAccessContacts = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    access: {
      ...state.access,
      contacts: {
        ...state.access.contacts,
        data: payload,
        isLoading: false,
        isLoaded: true,
      },
    },
  };
};

const downloadTable = (state, action) => {
  const { requestType, format } = action;
  const fileFormat = format;
  const selectColumns = ({
    // eslint-disable-next-line camelcase
    ticket_number,
    // eslint-disable-next-line camelcase
    request_status,
    // eslint-disable-next-line camelcase
    from_date,
    // eslint-disable-next-line camelcase
    to_date,
    // eslint-disable-next-line camelcase
    customer_name,
    // eslint-disable-next-line camelcase
    datacentre_name,
  }) => ({
    ticket_number,
    request_status,
    from_date,
    to_date,
    customer_name,
    datacentre_name,
  });
  const headers = [['Ticket Number', 'Status', 'Scheduled Date', 'End Date', 'Customer', 'Data Centre']];
  let data = [];
  const objectMaxLength = [];

  const requestsArray = Object.values(filteredRequestsSelector(state, requestType));
  // if requests are present
  if (requestsArray.length > 0) {
    requestsArray.forEach(request => {
      const reducedRequests = selectColumns(request);
      data = [...data, Object.values(reducedRequests)];
      // setting column width to header or value
      Object.values(reducedRequests).forEach((value, idx) => {
        // if column width not set
        if (objectMaxLength[idx] === undefined) {
          objectMaxLength[idx] = headers[0][idx].length < value.length ? value.length : headers[0][idx].length;
        } else if (objectMaxLength[idx] < value.length) {
          objectMaxLength[idx] = value.length;
        }
      });
    });
  } else {
    headers[0].forEach((header, idx) => {
      objectMaxLength[idx] = header.length;
    });
  }
  let wscols = [];
  // adds to wscol and adds 1 extra width to columns
  objectMaxLength.forEach(width => { wscols = [...wscols, { width: width + 1 }]; });
  const ws = XLSX.utils.aoa_to_sheet([...headers, ...data]);
  ws['!cols'] = wscols;
  let wb = null;
  if (fileFormat === CSV) {
    wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');
  } else {
    wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  }
  XLSX.writeFile(wb, `${requestType === ACCESS ? 'Access' : 'Service'}Report_${moment().format('DDMMYYYY')}.${format}`);
  return state;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_REQUESTS: {
      return filterRequests(state, action);
    }
    case CLEAR_FILTER: {
      return clearFilter(state, action);
    }
    case RESET_FILTER: {
      return resetFilter(state, action);
    }
    case REQUEST_LOADING: {
      return setLoading(state, action);
    }
    case SET_REQUEST: {
      return setRequest(state, action);
    }
    case SET_MOUNTED: {
      return setMounted(state, action);
    }
    case SET_DRAWER_ITEM: {
      return setDrawerItem(state, action);
    }
    case CHANGE_DRAWER_REQUEST: {
      return changeDrawerRequest(state, action);
    }
    case SET_CUSTOMERS_LOADING: {
      return setCustomerLoading(state, action);
    }
    case SET_CUSTOMERS: {
      return setCustomer(state, action);
    }
    case SET_ACCESS_CONTACTS_LOADING: {
      return setAccessContactsLoading(state, action);
    }
    case SET_ACCESS_CONTACTS: {
      return setAccessContacts(state, action);
    }
    case DOWNLOAD_TABLE: {
      return downloadTable(state, action);
    }
    default:
      return state;
  }
};

export default reducer;
