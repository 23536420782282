import { connect } from 'react-redux';
import { serviceRequestsSelector, accessRequestsSelector } from '../redux/selectors';
import { fetchAll, changeFilterRequests, resetFilters } from '../redux/actions';
import Dashboard from '../components/Dashboard';
import { SERVICE, STATUS, ACCESS } from '../redux/action-constants';

const mapStateToProps = state => ({
  services: serviceRequestsSelector(state),
  accesses: accessRequestsSelector(state),
  // TODO: Maintenance
});

const mapDispatchToProps = dispatch => ({
  fetchAll: () => dispatch(fetchAll()),
  filterService: filter => (dispatch(changeFilterRequests(SERVICE, filter, STATUS))),
  filterAccess: filter => (dispatch(changeFilterRequests(ACCESS, filter, STATUS))),
  resetFilters: requestType => (dispatch(resetFilters(requestType))),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
